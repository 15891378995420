import React from 'react'

import { Link } from 'gatsby'
import Layout from '~/components/layout'
import SEOEn from '~/components/seoEn'

const NotFoundPage = () => (
  <Layout isEn>
    <SEOEn seo={{ title: '404 - Page not found' }} />
    <div className="w-screen h-screen flex justify-center items-center">
      <div className="text-center">
        <h1 className="mb-2">404 - Page not found</h1>
        <Link to="/en">Go back to homepage</Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
